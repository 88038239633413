import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from '@/router/routes'
import templateRoutes from '@/router/template-routes'
import AuthGuard from '@/utils/auth/auth.guard'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [...routes, ...templateRoutes]
})

router.beforeEach(AuthGuard)

export default router
