import Vue from 'vue'

export const state = {
  loadingChunked: false,
  percentageUploadedChunked: null,
  questions: [],
  question: null,
  typesQuestion: []
}

export const getters = {
  questions: (state) => state.questions,
  question: (state) => state.question,
  typesQuestion: (state) => state.typesQuestion,
  loadingChunked: (state) => state.loadingChunked,
  percentageUploadedChunked: (state) => state.percentageUploadedChunked
}

export const mutations = {
  SET_QUESTIONS(state, payload) {
    state.questions = payload
  },
  SET_QUESTION(state, payload) {
    state.question = payload
  },
  SET_TYPES_QUESTION(state, payload) {
    state.typesQuestion = payload
  },
  SET_LOADING_CHUNKED(state, payload){
    state.loadingChunked = payload
  },
  SET_PERCENTAGE_UPLOADED_CHUNKED(state, payload){
    state.percentageUploadedChunked = payload
  },
  UPDATE_APPROVED_ANSWER_LIST_QUESTION(state, payload){
      state.questions.forEach(question => {
        if(question.id == payload.question_id){
          question.answers.forEach(answer => {
            if(payload.answer_id == answer.id || !payload.answer_id){
              answer.approved_at = new Date();
              answer.approved_by = {
                first_name: payload.user.first_name,
                last_name: payload.user.last_name,
              };
            } 
          })
        }
      })
  }
}

export const actions = {
  async getQuestions({ commit }, payload) {
    try {
      const response = (await Vue.axios.get(`/question?checklist_id=${payload.checklist_id}${payload.place_id ? `&place_id=${payload.place_id}` : ''}`))
        .data
      commit('SET_QUESTIONS', response)
      return response
    } catch (error) {
      return error
    }
  },
  async getQuestion({ commit }, payload) {
    try {
      const response = (await Vue.axios.get(`/question/${payload}`)).data
      commit('SET_QUESTION', response)
      return response
    } catch (error) {
      return error
    }
  },
  async getNextChecklistQuestion({ commit }, payload) {
    let route = payload.question_id
    ? `/get/question/${payload.checklist_id}?question_id=${payload.question_id}&place_id=${payload.place_id}${payload.unapproved_only ? '&unapproved_only=true' : ''}`
    : `/get/question/${payload.checklist_id}?place_id=${payload.place_id}${payload.unapproved_only ? '&unapproved_only=true' : ''}`
    try {
      const response = (await Vue.axios.get(route)).data
      return response
    } catch (error) {
      return error
    }
  },
  async getTypesQuestion({ commit }) {
    try {
      const response = (await Vue.axios.get(`/type/questions`)).data
      commit('SET_TYPES_QUESTION', response)
      return response
    } catch (error) {
      return error
    }
  },
  async updateOrCreateQuestion({ commit }, payload) {
    let response = null
    const formData = new FormData()
    formData.set('name', payload.name)
    formData.set('description', payload.description)
    formData.set('question_type_id', payload.question_type)
    formData.set('checklist_id', payload.checklist_id)
    formData.set('parent_id', payload.parent)
    let url = payload.id ? `/question/${payload.id}/` : '/question/'
    try {
      response = await Vue.axios({
        method: payload.id ? 'put' : 'post',
        url,
        data: formData,
        config: {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      })
      return response
    } catch (error) {
      return error
    }
  },
  async updateEnableQuestion({ commit }, payload) {
    let url = payload.id_blacklist_question 
      ? `/place_question_blacklist/?pk=${payload.id_blacklist_question}` 
      : `/place_question_blacklist/?question_id=${payload.id_question}&place_id=${payload.id_place}`
    try {
      let response = await payload.id_blacklist_question ? Vue.axios.delete(url, payload) : Vue.axios.post(url, payload)
      return response
    } catch (error) {
      return error
    }
  },
  async uploadImageQuestion({ commit }, payload) {
    const formData = new FormData()
    formData.append('question_id', payload.question_id)
    formData.append('image', payload.image)
    try {
      let response = await Vue.axios.post('/placeholder/question/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return response
    } catch (error) {
      return error.response
    }
  },
  async updatePdfRenderFromAnswer({ commit }, payload) {
    try {
      let response = await Vue.axios.post(`/answer/render_document/${payload.id_answer}`, {
        id: payload.id_answer
      })
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  },
  async createAnswer({ commit }, payload) {
    const formData = new FormData()
    formData.append('question_id', payload.question)
    formData.append('place_id', payload.place_id)
    formData.append('user_id', payload.user_id)
    formData.append('value', payload.value ?? '')
    if (payload.image) {
      formData.append('image', payload.image)
    }
    let url = payload.replace ? `/answer/?replace=${payload.replace}` : '/answer/'
    try {
      let response = await Vue.axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return response
    } catch (error) {
      return error
    }
  },
  async uploadVideoChunckedFromAnswer({ commit }, payload) {
    commit('SET_LOADING_CHUNKED', true)
    let {image} = payload
    let resp = null
    let first_upload = true
    let final_upload = false
    const chunkSize = 50 * 1024 * 1024;
    const filename = image.name;
    for (let start = 0; start < image.size; start += chunkSize) {
      const end = Math.min(start + chunkSize, image.size);
      const chunk = image.slice(start, end);
      let formData = new FormData()
      formData.append("question_id", payload.question)
      formData.append("place_id", payload.place_id)
      formData.append("user_id", payload.user_id)
      formData.append("file", chunk, filename)
      if(start > 0){
        first_upload = false
      }
       if (end === image.size) {
        final_upload = true
      }
      formData.append("first_upload", first_upload);
      formData.append("final_upload", final_upload) 
      try {
        resp = await Vue.axios.post(
          `/upload_video`,
           formData
        )
      } catch (error) {
        return error.response
      } finally {
      }
      const percent = (end / image.size) * 100;
      commit('SET_PERCENTAGE_UPLOADED_CHUNKED', percent);
    }
    commit('SET_LOADING_CHUNKED', false)
    commit('SET_PERCENTAGE_UPLOADED_CHUNKED', null);
    return resp
  },
  async markAnswerReplaced({ commit }, payload) {
    try {
      let response = await Vue.axios.put(
        `/answer/markasreplaced/${payload.id}?id=${payload.id}`,
         payload
        )
      return response
    } catch (error) {
      return error.response
    }
  },
  async addMissingAnswer({ commit }, payload) {
    try {
      let response = await Vue.axios.post(`/missinganswer?question_id=${payload.id_question}&place_id=${payload.id_place}&comment=${payload.comment}`, payload)
      return response
    } catch (error) {
      return error
    }
  },
  async updateAnswer({ commit }, payload) {
    try {
      let response = await Vue.axios.put(`/answer/${payload.id_answer}/`, payload)
      return response
    } catch (error) {
      return error
    }
  },
  updateApprovedAnswerListQuestion({commit}, payload){
    commit('UPDATE_APPROVED_ANSWER_LIST_QUESTION', payload)    
  },
  async updateApprovedAnswer({ commit }, payload) {
    try {
      let response = await Vue.axios.put(`/answer/approved/${payload.id_answer}`, payload)
      return response
    } catch (error) {
      return error.response
    }
  },
  async approveAllAnswers({ commit }, payload) {
    try {
      let response = await Vue.axios.put(`/answer/approve-all?question_id=${payload.id_question}&place_id=${payload.id_place}`, payload)
      return response
    } catch (error) {
      return error.response
    }
  },
  async addAllAnswersToDocument({ commit }, payload) {
    try {
      let response = await Vue.axios.put(`/answer/send-all-answers-to-doc?question_id=${payload.id_question}&place_id=${payload.id_place}`, payload)
      return response
    } catch (error) {
      return error.response
    }
  },

  async rejectAnswer({_}, payload) {
    try {
      let response = await Vue.axios.put(`/answer/rejected/${payload.id}` + `${payload.rejected_comment ? `?rejected_comment=${payload.rejected_comment}` : ''}`)
      return response
    } catch (error) {
      return error.response
    }
  },
  async retrieveAnswer({_}, payload) {
    try {
      let response = await Vue.axios.put(`/answer/restore/${payload}`)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  },
  async createQuestionComment({ _ }, payload) {
    try {
      let response = await Vue.axios.post('/comment/', payload)
      return response
    } catch (error) {
      return error
    }
  },
  async deleteQuestion({ commit }, payload) {
    try {
      const response = await Vue.axios.delete(`/question/${payload}`)
      return response
    } catch (error) {
      return error
    }
  },
  async deletePlaceholderImage({ commit }, payload) {
    try {
      const response = await Vue.axios.delete(`/placeholder/question/${payload}`)
      return response
    } catch (error) {
      return error
    }
  },
  async deleteAnswer({ commit }, payload) {
    try {
      const response = await Vue.axios.delete(`/answer/${payload}`)
      return response
    } catch (error) {
      return error
    }
  },
  async getQuestionOptions({ commit }) {
    try {
      const response = (await Vue.axios.get(`/questionoptions`)).data
      return response
    } catch (error) {
      return error
    }
  },
  async getQuestionOptionsFromQuestion({ commit }, payload) {
    try {
      const response = (await Vue.axios.get(`/search/questionoptions/${payload}`)).data
      return response
    } catch (error) {
      return error
    }
  },
  async updateOrCreateQuestionOption({ commit, dispatch }, payload) {
    try {
      let response
      if (payload.id) {
        response = await Vue.axios.put(`/questionoptions/${payload.id}/`, payload)
      } else {
        response = await Vue.axios.post('/questionoptions/', payload)
      }
      return response
    } catch (error) {
      return error
    }
  },
  async deleteQuestionOption({ commit }, payload) {
    try {
      const response = await Vue.axios.delete(`/questionoptions/${payload}`)
      return response
    } catch (error) {
      return error
    }
  },
  async changeOrderQuestions({ _ }, payload) {
    try {
      let response = await Vue.axios.put('/question/change_order/', payload)
      return response
    } catch (error) {
      return error
    }
  },
}
