import Vue from 'vue'

export const state = {
  checklists: [],
  checklistsUserPending: [],
  checklist: null,
  checklistDOCXProgress: {id: null, progress: 0},
  typesChecklist: []
}

export const getters = {
  checklists: (state) => state.checklists,
  checklistsUserPending: (state) => state.checklistsUserPending,
  checklist: (state) => state.checklist,
  checklistDOCXImagePerPageProgress: (state) => state.checklistDOCXImagePerPageProgress,
  checklistDOCXProgress: (state) => state.checklistDOCXProgress,
  typesChecklist: (state) => state.typesChecklist
}

export const mutations = {
  SET_CHECKLISTS(state, payload) {
    state.checklists = payload
  },
  SET_CHECKLISTS_USER_PENDING(state, payload) {
    state.checklistsUserPending = payload
  },
  SET_CHECKLIST(state, payload) {
    state.checklist = payload
  },
  SET_CHECKLIST_DOCX_IMAGE_PER_PAGE_PROGRESS(state, payload){
    state.checklistDOCXImagePerPageProgress = payload
  },
  SET_CHECKLIST_DOCX_PROGRESS(state, payload){
    state.checklistDOCXProgress = payload
  },
  SET_TYPES_CHECKLIST(state, payload) {
    state.typesChecklist = payload
  }
}

export const actions = {
  async getChecklists({ commit }, payload) {
    try {
      const response = (await Vue.axios.get(`/checklist/`, {
        params: payload
      })).data
      commit('SET_CHECKLISTS', response)
      return response
    } catch (error) {
      return error
    }
  },
  async getChecklistsUserPending({ commit }, payload) {
    try {
      const response = (await Vue.axios.get(`/mypending/checklist`)).data
      commit('SET_CHECKLISTS_USER_PENDING', response)
      return response
    } catch (error) {
      return error
    }
  },
  async getDocxFromPlaceChecklist({ commit }, payload) {
    try {
      const response = (
        await Vue.axios({
          method: 'get',
          url: `/DOCX/generate/${payload.place_checklist_id}`,
          params: {
            type: payload.type ? payload.type : undefined,
            autoupload: payload.autoupload ? true : undefined
          },
          responseType: 'blob',
          onDownloadProgress: progressEvent => {
            const totalBytes = progressEvent.total; // Tamaño total del archivo
            const downloadedBytes = progressEvent.loaded; // Bytes descargados hasta el momento
            const percentage = Math.round((downloadedBytes / totalBytes) * 100); // Porcentaje de descarga
            commit('SET_CHECKLIST_DOCX_PROGRESS', {id: payload.place_checklist_id, progress: percentage})
            if(percentage === 100){
              setTimeout(() => {
                commit('SET_CHECKLIST_DOCX_PROGRESS', {id: null, progress: 0})
              }, 200);              
            }
          }
        }
        )
      )
      return response
    } catch (error) {
      console.log(error)
      return error.response
    }
  },
  async getChecklist({ commit }, payload) {
    try {
      const response = (await Vue.axios.get(`/checklist/${payload}`)).data
      commit('SET_CHECKLIST', response)
      return response
    } catch (error) {
      return error
    }
  },
  async getTypesChecklist({ commit }) {
    try {
      const response = (await Vue.axios.get(`/checklist/type`)).data
      commit('SET_TYPES_CHECKLIST', response)
      return response
    } catch (error) {
      return error
    }
  },
  async updateOrCreateChecklist({ commit, dispatch }, payload) {
    try {
      let response
      if (payload.id) {
        response = await Vue.axios.put(`/checklist/${payload.id}/`, payload)
      } else {
        response = await Vue.axios.post('/checklist/', payload)
      }
      if (payload.project_id) {
        await dispatch('getChecklists')
      }
      return response
    } catch (error) {
      return error
    }
  },
  async duplicateChecklist({ commit }, payload) {
    try {
      const response = await Vue.axios.post(`/checkl/duplicate/?id=${payload.checklist_id}`)
      return response
    } catch (error) {
      return error.response
    }
  },
  async archiveOrDearchiveChecklist({ commit }, payload) {
    try {
      const response = await Vue.axios.put(
        `/checkl/mark-archived?checklist_id=${payload.checklist_id}${payload.unarchive ? '&unarchive=true' : ''}`
      )
      return response
    } catch (error) {
      return error
    }
  },
  async setAllAnswersHdFromPlaceChecklist({ commit }, payload) {
    try {
      const response = await Vue.axios.put(
        `/answer/turnhd?place_checklist_id=${payload.place_checklist_id}&hd=True`
      )
      return response
    } catch (error) {
      return error
    }
  },
  async approveAllAnswersFromPlaceChecklist({ commit }, payload) {
    try {
      const response = await Vue.axios.put(
        `/answer/approve-all/checklist_place?place_checklist=${payload.place_checklist}`
      )
      return response
    } catch (error) {
      return error
    }
  },
  async uploadPdfFromPlaceChecklist({ commit }, payload) {
    const formData = new FormData()
    formData.append('final_document', payload.file)
    try {
      let response = await Vue.axios.put(`/checkl/place/?id=${payload.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return response
    } catch (error) {
      return error.response
    }
  },
  async deleteChecklist({ commit }, payload) {
    try {
      const response = await Vue.axios.delete(`/checklist/${payload}`)
      return response
    } catch (error) {
      return error
    }
  },
}
