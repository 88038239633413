import Vue from 'vue'
import axios from 'axios';

export const state = {
  communes: [],
  businessUnits: [],
}
export const getters = {
  communes: ({ communes }) => communes,
  businessUnits: ({ businessUnits }) => businessUnits,
}
export const actions = {
  async getCommunes({ commit }) {
    try {
      const response = (await Vue.axios.get(`/commune/`)).data
      commit('setCommunes', response)
    } catch (error) {
      return error
    }
  },
  async getBusinessUnitsFromMsMaterials({ commit }, payload = {}) {
    try {
      const resp = (
        await axios({
          method: "get",
          url: `${process.env.VUE_APP_MS_REQUESTS_MATERIALS_URL}/api/maintenance/all_business_units`,
          headers: {
            access_token: `${process.env.VUE_APP_MS_REQUESTS_MATERIALS_API_KEY}`,
          },
        }
        )
      ).data;
      commit("SET_BUSINESS_UNITS", resp);
      return resp;
    } catch (error) {
      return error;
    }
  },
}
export const mutations = {
  setCommunes(vuexState, payload) {
    vuexState.communes = payload
  },
  SET_BUSINESS_UNITS(vuexState, payload) {
    vuexState.businessUnits = payload.map((item) => {
      return {
        ...item,
        name_code: `${item.name} (${item.sap_code})`,
      }
    })
  },
}
