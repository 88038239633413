import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import themeConfig from './theme-config'
import modules from './modules'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    ...modules,
    themeConfig
  },
  strict: process.env.DEV
})
