export const getCurrentUser = () => {
  let user = null
  try {
    user =
      localStorage.getItem('user') != null ? JSON.parse(localStorage.getItem('user')) : null
  } catch (error) {
    console.log('Utils/Guard', error)
    user = null
  }
  return user
}

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user))
    } else {
      localStorage.removeItem('user')
    }
  } catch (error) {
    console.log('Utils/Guard', error)
  }
}

export const setToken = (token) => {
  try {
    if (token) {
      localStorage.setItem('token', JSON.stringify(token))
    } else {
      localStorage.removeItem('token')
    }
  } catch (error) {
    console.log('Utils/Guard', error)
  }
}

export const getToken = () => {
  let token = null
  try {
    token =
      localStorage.getItem('token') != null ? JSON.parse(localStorage.getItem('token')) : null
  } catch (error) {
    console.log('Utils/Guard', error)
    token = null
  }
  return token
}

export const dataURLtoFile = (dataurl, filename) => {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export const geExtensionFileFromUrl = (url) => {
  const extension = url.split('.').pop();
  return extension.toLowerCase();
}