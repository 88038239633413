export default [
  //Auth
  {
    path: '/auth/login',
    name: 'login',
    component: () => import('@/view/pages/app/auth/Login'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/user/auth/login/google',
    component: () => import('@/view/pages/app/auth/VerifyLoginGoogle'),
    meta: {
      layout: 'full'
    }
  },
  //Main
  {
    path: '/',
    meta: { loginRequired: true },
    component: () => import('@/view/main/dashboards/analytics')
  },
  {
    path: '/app/projects',
    name: 'projects',
    meta: { loginRequired: true },
    component: () => import('@/view/pages/app/projects/Index')
  },
  {
    path: '/app/projects/:id',
    name: 'show-project',
    meta: { loginRequired: true },
    component: () => import('@/view/pages/app/projects/show/Show'),
    children: [
      {
        path: 'information',
        name: 'show-project-information',
        meta: { loginRequired: true },
        component: () => import('@/view/pages/app/projects/show/Show')
      },
      {
        path: 'places',
        name: 'show-project-places',
        meta: { loginRequired: true },
        component: () => import('@/view/pages/app/projects/show/Show')
      },
      {
        path: 'checklists',
        name: 'show-project-checklists',
        meta: { loginRequired: true },
        component: () => import('@/view/pages/app/projects/show/Show')
      }
    ]
  },
  {
    path: '/app/projects/:id/checklists/:checklistId',
    name: 'project-checklist',
    meta: { loginRequired: true },
    component: () => import('@/view/pages/app/projects/checklists/questions/Index')
  },
  //Places
  {
    path: '/app/places/:id',
    name: 'show-place',
    meta: { loginRequired: true },
    component: () => import('@/view/pages/app/places/show/Show'),
    children: [
      {
        path: 'information',
        name: 'show-place-information',
        meta: { loginRequired: true },
        component: () => import('@/view/pages/app/places/show/Show')
      },
      {
        path: 'users',
        name: 'show-place-users',
        meta: { loginRequired: true },
        component: () => import('@/view/pages/app/places/show/Show')
      },
      {
        path: 'checklists',
        name: 'show-place-checklists',
        meta: { loginRequired: true },
        component: () => import('@/view/pages/app/places/show/Show')
      }
    ]
  },
  {
    path: '/app/my-places',
    name: 'my-places',
    meta: { loginRequired: true },
    component: () => import('@/view/pages/app/places/my-places/Index')
  },
  {
    path: '/app/checklists/pending',
    name: 'my-checklists-pending',
    meta: { loginRequired: true },
    component: () => import('@/view/pages/app/checklists/my-checklists/Index')
  },
  {
    path: '/app/places/:id/checklists/:checklistId',
    name: 'place-checklist',
    meta: { loginRequired: true },
    component: () => import('@/view/pages/app/places/checklists/questions/Index')
  },
  //Plantillas
  {
    path: '/app/templates',
    name: 'templates',
    meta: { loginRequired: true },
    component: () => import('@/view/pages/app/checklists/Index')
  },
  {
    path: '/app/checklists/:id',
    name: 'show-checklist',
    meta: { loginRequired: true },
    component: () => import('@/view/pages/app/checklists/show/Show'),
    children: [
      {
        path: 'managment',
        name: 'show-checklist-managment',
        meta: { loginRequired: true },
        component: () => import('@/view/pages/app/checklists/show/Show')
      }
    ]
  },
  {
    path: '/app/users',
    name: 'users',
    meta: { loginRequired: true },
    component: () => import('@/view/pages/app/users/Index')
  }
]
