export default {
  state: {
    version: '2.0',
    theme: 'light', // light - dark
    contentWidth: 'full', // full - boxed
    sidebarCollapsed: false,
    sidebarCollapseButton: true,
    layout: 'horizontal', // vertical - horizontal
    navigationFull: false,
    navigationBg: false,
    direction: 'ltr' // ltr - rtl
  }
}
