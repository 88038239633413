import Vue from 'vue'

export const state = {
  graphs: null,
}

export const getters = {
  graphs: state => state.graphs,
}

export const mutations = {
  SET_GRAPHS(state, payload) {
    state.graphs = payload
  },
}

export const actions = {
  async getGraphs({ commit }, payload) {
    try {
      const response = (await Vue.axios.get('/graphics', { params: payload })).data
      commit('SET_GRAPHS', response)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  },
}
