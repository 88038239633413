import Vue from 'vue'

export const state = {
  users: [],
  usersPaginated: {
    items: 0,
    pages: 0,
    data: []
  },
  roles: []
}

export const getters = {
  users: (state) => state.users,
  usersPaginated: (state) => state.usersPaginated,
  roles: (state) => state.roles
}

export const mutations = {
  SET_USERS(state, payload) {
    state.users = payload
  },
  SET_USERS_PAGINATED(state, payload) {
    state.usersPaginated = payload
  },
  SET_ROLES(state, payload) {
    state.roles = payload
  },
  SET_NEW_USER(state, payload) {
    state.users.push(payload)
  }
}

export const actions = {
  async getUsers({ commit }) {
    try {
      const response = (await Vue.axios.get(`/user`)).data
      commit('SET_USERS', response)
      return response
    } catch (error) {
      return error
    }
  },
  async getUsersPaginated({ commit }, payload) {
    try {
      const response = (
        await Vue.axios.get(`/get-users`, {params: payload})
      ).data
      commit('SET_USERS_PAGINATED', response)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  },
  async getRoles({ commit, getters }) {
    try {
      if(!getters.roles.length){
        const response = (await Vue.axios.get(`/rol`)).data
        commit('SET_ROLES', response)
        return response
      }
    } catch (error) {
      return error
    }
  },
  async updateProfileUser({ commit, dispatch }, payload) {
    try {
      const formData = new FormData()
      const { first_name, last_name, email, rut, rol, phone, image } = payload
      if (first_name) formData.append('first_name', first_name)
      if (last_name) formData.append('last_name', last_name)
      if (rut) formData.append('rut', rut)
      if (email) formData.append('email', email)
      if (rol) formData.append('rol', rol)
      if (phone) formData.append('phone', phone)  
      if (image) formData.append('image', image)  
      const response = await Vue.axios.put(`/user/${payload.id}/`, formData)
      await dispatch('auth/getProfile', {}, { root: true })
      return response
    } catch (error) {
      return error.response
    }
  },
  async updateOrCreateUser({ _ }, payload) {
    const { first_name, last_name, rol, email, rut, password } = payload
    const formData = new FormData()
    if (first_name) formData.append('first_name', first_name)
    if (last_name) formData.append('last_name', last_name)
    if (rut) formData.append('rut', rut)
    if (email) formData.append('email', email)
    if (rol) formData.append('rol', rol)
    if (password) formData.append('password', password)

    try {
      let response
      if (payload.id) {
        response = await Vue.axios.put(`/user/${payload.id}/`, formData)
      } else {
        response = await Vue.axios.post('/user/', formData)
      }
      return response
    } catch (error) {
      return error.response
    }
  },
  async deleteUser({ commit }, payload) {
    try {
      const response = await Vue.axios.delete(`/user/${payload}`)
      return response
    } catch (error) {
      return error
    }
  }
}
