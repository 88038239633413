import Vue from 'vue'
import VueCompositionAPI, { createApp, h } from '@vue/composition-api'

import store from './store'
import router from './router'
import App from './App.vue'

//Vuelidate
import Vuelidate from 'vuelidate'

// Vue Page Transition
import VuePageTransition from 'vue-page-transition'

// Bootstrap
import BootstrapVue from 'bootstrap-vue'
import { ToastPlugin } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Highlightjs
import VueHighlightJS from 'vue-highlightjs'
import 'highlight.js/styles/mono-blue.css'

// Apexcharts
import VueApexCharts from 'vue-apexcharts'

// Bootstrap Plugin
Vue.use(BootstrapVue)
Vue.use(ToastPlugin)

// Vue Page Transition
Vue.use(VuePageTransition)

// Highlight
Vue.use(VueHighlightJS)

// Apexchart
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

//Vuelidate
Vue.use(Vuelidate)

//Vue Select
import vSelect from 'vue-select'
Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css'

// Style
require('@/assets/icons/iconly/index.min.css')
require('@/assets/icons/remix-icon/index.min.css')
require('@/assets/scss/app.scss')

// Composition Api
Vue.use(VueCompositionAPI)

import './plugins/axios'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
