import Vue from 'vue'

export const state = {
  places: [],
  myPlaces: [],
  placeUsers: [],
  placeChecklists: [],
  placeChecklist: null,
  place: null
}

export const getters = {
  places: (state) => state.places,
  myPlaces: (state) => state.myPlaces,
  place: (state) => state.place,
  placeChecklists: (state) => state.placeChecklists,
  placeChecklist: (state) => state.placeChecklist,
  placeUsers: (state) => state.placeUsers
}

export const mutations = {
  SET_PLACES(state, payload) {
    state.places = payload
  },
  SET_MY_PLACES(state, payload) {
    state.myPlaces = payload
  },
  SET_PLACE_USERS(state, payload) {
    state.placeUsers = payload
  },
  SET_PLACE_CHECKLISTS(state, payload) {
    state.placeChecklists = payload
  },
  SET_PLACE_CHECKLIST(state, payload) {
    state.placeChecklist = payload
  },
  SET_PLACE(state, payload) {
    state.place = payload
  },
  SET_NEW_PLACE(state, payload) {
    state.places.push(payload)
  }
}

export const actions = {
  async getPlaces({ commit }, payload) {
    try {
      const response = (await Vue.axios.get(`/place/`, { params: { ...payload } })).data
      if (payload.user_id) {
        commit('SET_MY_PLACES', response)
      } else {
        commit('SET_PLACES', response)
      }
      return response
    } catch (error) {
      return error
    }
  },
  async getPlaceUsers({ commit }, payload) {
    try {
      const response = (await Vue.axios.get(`/assign/place/user?place_id=${payload.place_id}`))
        .data
      commit('SET_PLACE_USERS', response)
      return response
    } catch (error) {
      return error
    }
  },
  async getPlace({ commit }, payload) {
    try {
      const response = (await Vue.axios.get(`/place/${payload}`)).data
      commit('SET_PLACE', response)
      return response
    } catch (error) {
      return error
    }
  },
  async getPlaceChecklists({ commit }, payload) {
    try {
      const response = (await Vue.axios.get(`/checkl/place?place_id=${payload.place_id}${payload.checklist_id ? `&checklist_id=${payload.checklist_id}` : ''}${payload.section ? `&section=${payload.section}` : ''}`)).data
      if(Array.isArray(response)){
        commit('SET_PLACE_CHECKLISTS', response)
        commit('SET_PLACE_CHECKLIST', response[0])
      }else{
        commit('SET_PLACE_CHECKLIST', response)

      }
      if(response)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  },
  async getPlaceChecklistProgress({ commit }, payload) {
    try {
      const response = (
        await Vue.axios.get(
          `/progress/checklist/place/${payload.checklist_id}?place_id=${payload.place_id}`
        )
      ).data
      return response
    } catch (error) {
      return error
    }
  },
  async updateOrCreatePlace({ commit, dispatch }, payload) {
    try {
      let response
      if (payload.id) {
        response = await Vue.axios.put(`/place/${payload.id}/`, payload)
      } else {
        response = await Vue.axios.post('/place/', payload)
      }
      if (payload.project_id) {
        await dispatch('getPlaces', { project_id: payload.project_id })
      }
      return response
    } catch (error) {
      return error
    }
  },
  async updateOrCreatePlaceUser({ commit, dispatch }, payload) {
    try {
      let response
      if (payload.id) {
        response = await Vue.axios.put(`/assign/place/user/${payload.id}/`, payload)
      } else {
        response = await Vue.axios.post('/assign/place/user/', payload)
      }
      return response
    } catch (error) {
      return error
    }
  },
  async createPlaceChecklist({ _ }, payload) {
    try {
      let response = await Vue.axios.post('/checkl/place/', payload)
      return response
    } catch (error) {
      return error
    }
  },
  async deletePlace({ commit }, payload) {
    try {
      const response = await Vue.axios.delete(`/place/${payload}`)
      return response
    } catch (error) {
      return error
    }
  },
  async deletePlaceUser({ commit }, payload) {
    try {
      const response = await Vue.axios.delete(`/assign/place/user/${payload}`)
      return response
    } catch (error) {
      return error
    }
  },
  async deletePlaceChecklist({ commit }, payload) {
    try {
      const response = await Vue.axios.delete(`/checkl/place/${payload}`)
      return response
    } catch (error) {
      return error
    }
  }
}
