import { setCurrentUser } from '../common'
import store from '@/store'

export default async (to, from, next) => {
  if (to.matched.some((record) => record.meta.loginRequired)) {
    const user = store.getters['auth/userAuth'] || (await store.dispatch('auth/getProfile'))
    if (user.id) {
      if (to.path === '/auth/login') {
        next('/')
      } else {
        next()
      }
    } else {
      setCurrentUser(null)
      next('/auth/login')
    }
  } else {
    next()
  }
}
