import Vue from 'vue'

export const state = {
  projects: [],
  projectsPaginated: {
    items: 0,
    pages: 0,
    data: []
  },
  project: null,
  projectChecklists: [],
  typesProject: [],
  businessUnits: [],
  sections: [],
  sectionsUser: [],
}

export const getters = {
  projects: (state) => state.projects,
  projectsPaginated: (state) => state.projectsPaginated,
  project: (state) => state.project,
  projectChecklists: (state) => state.projectChecklists,
  typesProject: (state) => state.typesProject,
  businessUnits: (state) => state.businessUnits,
  sections: (state) => state.sections,
  sectionsUser: (state) => state.sectionsUser,
}

export const mutations = {
  SET_PROJECTS(state, payload) {
    state.projects = payload
  },
  SET_PROJECTS_PAGINATED(state, payload) {
    state.projectsPaginated = payload
  },
  SET_PROJECT(state, payload) {
    state.project = payload
  },
  SET_PROJECT_CHECKLISTS(state, payload) {
    state.projectChecklists = payload
  },
  SET_TYPES_PROJECT(state, payload) {
    state.typesProject = payload
  },
  SET_BUSINESS_UNITS(state, payload) {
    state.businessUnits = payload
  },
  SET_NEW_PROJECT(state, payload) {
    state.projects.push(payload)
  },
  SET_SECTIONS(state, payload) {
    state.sections = payload
  },
  SET_SECTIONS_USER(state, payload) {
    state.sectionsUser = payload
  },
}

export const actions = {
  async getProjects({ commit }, payload) {
    try {
      const response = (
        await Vue.axios.get(`/project`, {
          params: {
            ...payload,
            name: payload.name.length ? payload.name : undefined
          }
        })
      ).data
      commit('SET_PROJECTS', response)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  },
  async getProjectsPaginated({ commit }, payload) {
    try {
      const response = (
        await Vue.axios.get(`/project-list`, {params: payload})
      ).data
      commit('SET_PROJECTS_PAGINATED', response)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  },
  async getProject({ commit }, payload) {
    try {
      const response = (await Vue.axios.get(`/project/${payload}`)).data
      commit('SET_PROJECT', response)
      return response
    } catch (error) {
      return error
    }
  },
  async getProjectChecklists({ commit }, payload) {
    try {
      const response = (
        await Vue.axios.get(`/assign_project/checklist?project_id=${payload.project_id}`)
      ).data
      commit('SET_PROJECT_CHECKLISTS', response)
      return response
    } catch (error) {
      return error
    }
  },
  async getTypesProject({ commit }) {
    try {
      const response = (await Vue.axios.get(`/type/project`)).data
      commit('SET_TYPES_PROJECT', response)
      return response
    } catch (error) {
      return error
    }
  },
  async getBusinessUnits({ commit }) {
    try {
      const response = (await Vue.axios.get(`/business_units`)).data
      commit('SET_BUSINESS_UNITS', response)
      return response
    } catch (error) {
      return error
    }
  },
  async updateOrCreateProject({ _ }, payload) {
    const { name, code, description, project_type, status, image } = payload
    const formData = new FormData()
    if (name) formData.append('name', name)
    if (code) formData.append('code', code)
    if (description) formData.append('description', description)
    if (project_type) formData.append('project_type', Number(project_type))
    if (status) formData.append('status', Number(status))
    if (image) formData.append('image', image)

    try {
      let response
      if (payload.id) {
        response = await Vue.axios.put(`/project/${payload.id}/`, formData)
      } else {
        response = await Vue.axios.post('/project/', formData)
      }
      return response
    } catch (error) {
      return error
    }
  },
  async createProjectChecklist({ _ }, payload) {
    try {
      let response = await Vue.axios.post('/assign_project/checklist/', payload)
      return response
    } catch (error) {
      return error
    }
  },
  async deleteProject({ commit }, payload) {
    try {
      const response = await Vue.axios.delete(`/project/${payload}`)
      return response
    } catch (error) {
      return error
    }
  },
  async deleteProjectChecklist({ commit }, payload) {
    try {
      const response = await Vue.axios.delete(`/assign_project/checklist/${payload}`)
      return response
    } catch (error) {
      return error
    }
  },
  async getSections({ commit }) {
    try {
      const response = (await Vue.axios.get(`/sections`)).data
      commit('SET_SECTIONS', response)
      return response
    } catch (error) {
      return error
    }
  },
  async getSectionsUser({ commit }, payload) {
    try {
      const response = (await Vue.axios.get(`/sections?place_id=${payload.place_id}`)).data
      commit('SET_SECTIONS_USER', response)
      return response
    } catch (error) {
      return error
    }
  },
}
