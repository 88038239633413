import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { getToken, setCurrentUser, setToken } from '@/utils/common'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL
})

instance.defaults.headers.common['Content-Type'] = 'application/json'
instance.interceptors.request.use(
  (config) => {
    const tokenUser = getToken()
    if (tokenUser) {
      config.headers = {
        Authorization: `Bearer ${tokenUser.token}`
      }
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const originalRequest = error.config

    // token expired
    if (error.response.status === 401 && error.response.data.code === 'token_not_valid') {
      originalRequest._retry = true
    }
    console.log('Error en interceptor', error)
    if (error.response) {
      switch (error.response.status) {
        case 400:
          console.log('warning', error.response.data)
          if (
            error.response.data &&
            error.response.data.refresh &&
            error.response.data.refresh[0] === 'This field is required.'
          ) {
            setToken(null)
            setCurrentUser(null)
            Vue.router.push('/')
          }
          break
        case 401:
          // No autorizado
          const token = getToken()
          if (token) {
            try {
              setToken(null)
              const access_token = (
                await instance.post('auth/token/refresh', {
                  refresh: token.refresh
                })
              ).data
              setToken(access_token)
              return instance(originalRequest)
            } catch (error) {
              console.log('error in refresh', error)
            }
          }
          break
        case 403:
          console.log('warning', error.response.data)
          break
        case 404:
          break
        case 422:
          break
        case 423:
          console.log('warning', error.response.data)
          break
        case 500:
          console.log('error', error.response.data)
          break
        default:
      }
    }
    return Promise.reject(error)
  }
)

Vue.use(VueAxios, instance)
